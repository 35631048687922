import { FancyGallery } from "../components";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import leftArrow from "../assets/images/external-left-arrow.png";
import { Helmet } from "react-helmet";

function Gallery(props) {
  const emailAddy = `mailto:${props.email}`;

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Waikiki | {props.name}</title>
      </Helmet>
      <section id="gallery">
        <div className="container-lg mt-60 mb-60">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11 pb-1">
              <Button
                className="ps-0 pe-5"
                as={Link}
                variant="link"
                style={{ color: "white", fontSize: "1rem" }}
                to="/artists"
              >
                <img
                  style={{ marginRight: ".5rem" }}
                  src={leftArrow}
                  alt="arrow left"
                />
                Waikiki Artists
              </Button>
              <Button
                className="ps-0"
                variant="link"
                style={{ color: "white", fontSize: "1rem" }}
                href="https://sacredarthawaii.com/artists"
              >
                <img
                  style={{ marginRight: ".5rem" }}
                  src={leftArrow}
                  alt="arrow left"
                />
                All Sacred Art Tattoo Artists
              </Button>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-11 pb-5">
              <img
                src={props.pic}
                alt="about"
                className="float-start me-3 me-md-0 mb-3 pe-md-4 pe-lg-5 mb-md-0"
                style={{ width: "45%" }}
              />
              <h5 className="about_title">{props.fullName}</h5>
              <p className="about_text">
                {props.about.split(" ").map((word, i) =>
                  word.startsWith("https") ? (
                    <a
                      key={i}
                      href={word}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-underline"
                    >
                      {word}
                    </a>
                  ) : (
                    word + " "
                  )
                )}
              </p>
              <div className="col-12">
                {props.insta || props.facebook || props.email ? (
                  <h5 className="mt-20">Contact {props.name}</h5>
                ) : (
                  ""
                )}
                <ul className="social">
                  {props.email ? (
                    <li>
                      <a href={emailAddy}>
                        <FaEnvelope size={30} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.facebook ? (
                    <li>
                      <a href={props.facebook}>
                        <FaFacebook size={30} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.insta ? (
                    <li>
                      <a href={props.insta}>
                        <FaInstagram size={30} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <Link to="/contact" state={{ artist: props.name }}>
                  <Button variant="light">
                    Book an appointment with {props.name}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-11">
              <FancyGallery name={props.name} files={props.files} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery;
