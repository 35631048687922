const artistsList = [
  {
    name: "Jay",
    files: "Jay",
    fullName: "Jay Rascal",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/Jay.jpg",
    about: "To view more of my work, check out my Instagram below!",
    instagram: "https://instagram.com/inkedbyjay_5150",
    email: "",
    facebook: "",
    url: "/gallery/jay",
  },
  {
    name: "Nate",
    files: "Nate",
    fullName: "Nate Rose",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/Nate.jpeg",
    about:
      "Nate Rose was raised on the island of Maui. He specializes in Traditional American, Japanese, and contemporary Polynesian tattooing in bold bright color or black and grey. Bring your own design or pick from the many classic or custom tattoo flash he has hand painted! To check out more of his artwork and past tattoos, take a peek at his IG account.",
    instagram: "https://www.instagram.com/nate_rosetattoo",
    facebook: "",
    email: "",
    url: "/gallery/nate",
  },
  {
    name: "Matt",
    files: "Matt",
    fullName: "Matt Towns",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/Matt.jpg",
    about:
      "Born and raised in Northern Virginia 30 mins outside of D.C. Tattooing for the better part of 10 years and still enjoying it every day. Most of the shops I have worked in are street shops, that offer a wide variety of styles and concepts. Moved to the beautiful state of Hawaii almost 2 years ago to further my tattoo knowledge and experience. Have been enjoying working with the Sacred Art team the entire time I have been living on the island. I prefer to do traditional style tattoos but will be more than happy to work with you on any piece you might be interested in. Come on in and get something that will last you a lifetime!",
    instagram: "https://www.instagram.com/short_round_towns",
    email: "",
    facebook: "",
    url: "/gallery/matt",
  },
  {
    name: "Brags",
    files: "Brags",
    fullName: "Brags",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/brags.jpg",
    about:
      "Aloha, my name is Brags. I specialize in black and grey tattoos, but I can bring all your tattoo ideas to life! Feel free to check out more of my art on my Instagram.",
    instagram: "https://www.instagram.com/brags_tattoo",
    email: "",
    facebook: "",
    url: "/gallery/brags",
  },
  {
    name: "Tweety",
    files: "Tweety",
    fullName: "Tweety Higgins",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/tweety.jpg",
    about:
      "Tweety Higgins was born and raised in Portland, Oregon. She moved to Hawai'i originally for habitat restoration and has worked out on the Northwestern Hawaiian atolls before beginning her tattooing career. She apprenticed out of Mid Pacific tattoo in Lahaina, Maui in 2021 and moved to Honolulu after the fires burned down the west side and town. She has been tattooing for a little over two years and mostly loves doing floral, fine line, traditional, black & grey, and color. She is pursuing illustrative realism tattoos. Outside of the shop she works on personal art projects, and loves to swim, surf, and ride jet ski.",
    instagram: "https://www.instagram.com/tweetytattoos",
    email: "",
    facebook: "",
    url: "/gallery/tweety",
  },
  {
    name: "Curtis",
    files: "Curtis",
    fullName: "Curtis Haggerty",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/curtis.jpg",
    about:
      "Curtis Haggerty has been tattooing for more than 25 years mostly in the mountains of Colorado with a 7 year stay in Fresno California. As far as style of tattooing I feel very versatile but with a strong basis in the tradition style. As far as Curtis as a person I have one daughter and my wife and I have been together seventeen years now. We are both from a Native American background and participate in traditional activities and lifestyle. I am also interested in the yogic practices from India.",
    instagram: "https://www.instagram.com/buffalohearttattoo",
    email: "",
    facebook: "",
    url: "/gallery/curtis",
  },
  {
    name: "Dustin",
    files: "Dustin",
    fullName: "Dustin Gormley",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/dustin.jpg",
    about:
      "Dustin started tattooing in 2014 in Dayton, Ohio. Drawn to the bold imagery of Traditional American style tattoos, he left college to pursue an apprenticeship under his mentor at Tattoo Tech. He spent years working in street shops, gaining experience in various styles, from Traditional Americana to classic Japanese, sacred geometry, and fine-line work. Now based in Waikiki at Sacred Art Tattoo, Dustin enjoys working with traditional designs and reinterpreting island motifs while staying true to the fundamentals of tattooing. He values professionalism, a positive client experience, and the tradition of knowledge-sharing within the tattoo community. Outside of tattooing, he paints and studies art history, philosophy, and meditation. See more of his work at https://tattoooos.com/portfolio",
    instagram: "https://www.instagram.com/dustingormley/",
    facebook: "",
    email: "",
    url: "/gallery/dustin",
  },
  {
    name: "Karl",
    files: "Karl",
    fullName: "Karl",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/karl.jpg",
    about:
      "Originally from London, UK, Karl has been tattooing since 2004. Starting off as a graphic designer and freelance artist he was offered an apprenticeship at a walk-in street shop in London. Over the years he's had the opportunity to travel and work at some of the best tattoo shops Europe and the US have to offer, learning and improving his skill as a tattooer and artist along the way. His favorite things to tattoo are bold American traditional/old school, Japanese and fine line tattoos.",
    instagram: "https://www.instagram.com/cooper.tattoo",
    facebook: "",
    email: "",
    url: "/gallery/karl",
  },
  {
    name: "Maddie (Piercer)",
    files: "Maddie",
    fullName: "Maddie",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/maddie.jpg",
    about: "Check out my piercings below!",
    instagram: "",
    facebook: "",
    email: "",
    url: "/gallery/maddie (Piercer)",
  },
  {
    name: "Jared",
    files: "Jared",
    fullName: "Jared",
    location: "Waikiki",
    pic: "https://sacredarthawaii.com/images/artists/jared.jpg",
    about:
      "Jared is from the beautiful North Shore of Oahu, and has spent most of his tattooing career in Lahaina, Maui. Now back in Honolulu, we are proud to have him on the team in Waikiki! Jared loves all styles of tattooing, and specializes in Polynesian Tribal, American Traditional, and Fineline Black and Grey. Book your next appointment or come walk-in today, and let’s make your ideas come to life!",
    instagram: "https://www.instagram.com/sailorjaredtattoo",
    facebook: "",
    email: "",
    url: "/gallery/jared",
  },
];

export default artistsList;
